<template>
  <section class="expendParts" id="foodSpecialEquip">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2><span class="bold">Special equipment</span> for production of food and beverage</h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="page-list-text">
          <ul>
            <li v-for="itemList in list" :key="itemList">{{itemList}}</li>
          </ul>
        </v-col>
        <v-col cols="12" sm="6">
          <v-img src="../../assets/food-special.jpg" height="525"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'food-specialEquip',
  data () {
    return {
      list: ['Equipment for tank cleaning', 'Bottle and jar cleaning equipment', 'Bottle, jar and crate washing machines', 'Crate conveyors', 'Bottle ejectors', 'Bottling machinery', 'Dispensing heads', 'Bottling units', 'Control, regulating, conveying and packaging systems', 'Containers, kegs, barrels etc.', 'Filling Systems', 'Glass working machines Cooling and drying', 'Cooling, drying, heating systems', 'Shrink Packaging', 'Adsorption Technology', 'Filtration systems', 'Separators, dispensers and taps']
    }
  }
}
</script>

<style scoped>

</style>
