<template>
  <section class='food'>
    <general-second :name="page.pageName" :descript="page.description"></general-second>
    <food-motors></food-motors>
    <food-special-equip class="gray"></food-special-equip>
    <food-hydraulics></food-hydraulics>
    <food-sensors class="gray"></food-sensors>
    <food-laboratory></food-laboratory>
    <food-hvac class="gray"></food-hvac>
  </section>
</template>

<script>
import GeneralSecond from '@/components/GeneralSecond'
import FoodMotors from '@/components/food/food-motors'
import FoodHydraulics from '@/components/food/food-hydraulics'
import FoodSpecialEquip from '@/components/food/food-specialEquip'
import FoodHvac from '@/components/food/food-hvac'
import FoodSensors from '@/components/food/food-sensors'
import FoodLaboratory from '@/components/food/food-laboratory'
export default {
  name: 'Food',
  components: {
    FoodLaboratory,
    FoodSensors,
    FoodHvac,
    FoodSpecialEquip,
    FoodHydraulics,
    FoodMotors,
    GeneralSecond
  },
  data () {
    return {
      page: {
        pageName: 'Food',
        description: ''
      }
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length > 0) {
      location.href = `${location.href}#${this.$route.params.id}`
    }
  },
  metaInfo: {
    title: 'Food',
    titleTemplate: '%s - VERTEX GmbH',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  }
}
</script>

<style>

</style>
