<template>
  <section class="expendParts" id="foodMotors">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2><span class="bold">swivel joints</span> and other metal components</h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <p>Own production of connecting elements from different metals (steel, stainless steel, aluminum, etc.) for equipment of European and American OEMs.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="d-sm-flex">
          <img src="../../assets/swiwel-joints.jpg" width="100%" alt="Own production of connecting elements">
        </v-col>
        <v-col cols="12" sm="6" class="page-list-text">
          <ul>
            <li v-for="itemList in list" :key="itemList">{{itemList}}</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'food-motors',
  data () {
    return {
      list: ['Aluminum connecting elements according to individual technical requirements and drawings', 'Stainless steel swivels for filling equipment in the food industry with European certification required by international standards']
    }
  }
}
</script>

<style>

</style>
