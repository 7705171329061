<template>
  <section class="expendParts" id="foodHvac">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2><span class="bold">Industrial pumps</span> and compressors</h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="page-list-text">
          <ul>
            <li v-for="itemList in list" :key="itemList">{{itemList}}</li>
          </ul>
        </v-col>
        <v-col cols="12" sm="6">
          <v-img src="../../assets/industrial-pumps.jpg" height="525"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'food-hvac',
  data () {
    return {
      list: ['Expertise in global pump market', 'Strong cooperation with European manufacturers', 'Wide scope of suppliers for spare parts.']
    }
  }
}
</script>

<style scoped>

</style>
