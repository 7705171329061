<template>
  <section class="expendParts" id="foodHydraulics">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2><span class="bold">Hydraulics</span>, hydraulic parts and assemblies</h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-img src="../../assets/hydraulics.jpg" height="525"></v-img>
        </v-col>
        <v-col cols="12" sm="6" class="page-list-text">
          <ul>
            <li v-for="itemList in list" :key="itemList">{{itemList}}</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'food-hydraulics',
  data () {
    return {
      list: ['Filters', 'Valves', 'Bottle, jar and crate washing machines', 'Pressure chambers', 'Control Units', 'Hydrostats', 'Fittings', 'Tools']
    }
  }
}
</script>

<style scoped>

</style>
