<template>
  <section class="expendParts" id="foodSensors">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2><span class="bold">Measuring equipment </span> and sensors </h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <p>All types of measuring equipment for food and beverage industry to ensure efficiency and safety of all production processes.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="page-list-text">
          <ul>
            <li v-for="itemList in list" :key="itemList">{{itemList}}</li>
            <p><b>and other instruments for measuring time and frequency</b></p>
          </ul>
        </v-col>
        <v-col cols="12" sm="6">
          <v-img src="../../assets/Measuring.jpg" height="525"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'food-sensors',
  data () {
    return {
      list: ['Pressure', 'Temperature', 'Level', 'Power/energy', 'Consumption']
    }
  }
}
</script>

<style scoped>

</style>
