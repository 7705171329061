<template>
  <section class="expendParts" id="foodLaboratory">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2><span class="bold">Laboratory </span> equipment</h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <p>Different types of laboratory equipment and conveyor systems accessories for the beverage industry</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-img src="../../assets/Laboratory.jpg" height="525"></v-img>
        </v-col>
        <v-col cols="12" sm="6" class="page-list-text">
          <ul>
            <li v-for="itemList in list" :key="itemList">{{itemList}}</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'food-laboratory',
  data () {
    return {
      list: ['Manual samplers', 'Automatic regulate samplers', 'Laboratory Accessories', 'Control units']
    }
  }
}
</script>

<style scoped>

</style>
